import React from 'react';

import { grid, list, card, paginationBox, listBox, statusBox } from './video-listing.module.scss';
import { ISection } from '../../models/section.model';
import { IVideo } from '../../models/video.model';
import { TStatus } from '../../models/status.model';
import useTranslations from '../../hooks/use-translations';

import Section from '../hoc/section';
import VideoCard from '../molecules/video-card';
import Pagination, { IPaginationProps } from '../molecules/pagination';
import Loader from '../atoms/loader';

interface IBlogListingSection extends ISection {
    items: {
        videos: IVideo[];
    };
    settings: {
        status: TStatus;
        paginationPaths: string[];
    };
}

interface IBlogListingProps {
    className?: string;
    section: IBlogListingSection;
    TitleTag?: React.ElementType;
    paginationProps?: IPaginationProps;
}

const VideoListing: React.FC<IBlogListingProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const {
        sectionId,
        css,
        style,
        items: { videos },
        settings: { status, paginationPaths },
    } = section;

    const t = useTranslations('VideoListing');

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
            }}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            <div className={listBox}>
                {status === 'loading' && (
                    <div className={statusBox}>
                        <Loader />
                    </div>
                )}
                {status === 'error' && (
                    <div className={statusBox}>
                        <p>{t.error}</p>
                    </div>
                )}
                {status === 'success' &&
                    (videos.length > 0 ? (
                        <ul className={list}>
                            {videos.map((video) => {
                                return (
                                    <li key={`video-card-item-${video.videoId}`}>
                                        <VideoCard videoCard={video} className={card} />
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <div className={statusBox}>
                            <p>{t.empty}</p>
                        </div>
                    ))}
            </div>
            <Pagination className={paginationBox} paginationPaths={paginationPaths} />
        </Section>
    );
};

export default VideoListing;
